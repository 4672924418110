import Link from 'next/link';

import { CustomImage } from '@components/Image/Image';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { BaseBlog } from '@interfaces/Blog';
import { CapitaliseSentence } from '@lib/formatText';
import { normaliseRoute } from '@lib/formatUrl';

type Props = {
  blog: BaseBlog;
} & Record<string, any>;

export function BlogCard({ blog, ...props }: Props) {
  const slug = normaliseRoute(blog.slug);

  // only show Estimated reading time when there is estimatedReadingTime and the blog is not a recipe
  const shouldShowReadingTime = (data) =>
    data.estimatedReadingTime && data.category !== 'Recipes';

  return (
    <Link
      prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
      href={slug}
      aria-label={blog.title}
      className="group/blogcard block h-full max-w-[320px] sm:max-w-[400px]"
    >
      <div
        className="card m-0 flex h-full flex-col border-none bg-white px-0 transition duration-200 group-hover/blogcard:border group-hover/blogcard:border-solid group-hover/blogcard:border-orange group-hover/blogcard:bg-grey-light dark:bg-grey-dark dark:group-hover/blogcard:bg-grey-dark"
        {...props}
      >
        <CustomImage image={blog.featuredImage} width={400} height={400} />
        <h2
          className="mb-2 mt-4 p-1 pl-4 font-secondary text-2xl uppercase text-grey-dark underline decoration-grey-mid 
          decoration-1 underline-offset-[0.1em] transition duration-200
        group-hover/blogcard:text-black group-hover/blogcard:decoration-orange 
        group-hover/blogcard:decoration-3 group-hover/blogcard:duration-200 
        dark:text-white dark:group-hover/blogcard:text-grey-mid
        "
        >
          {blog.title}
        </h2>
        <Paragraph className="m-0 mb-4 pl-4 text-sm ">
          {blog.author && (
            <>
              Posted by {blog.author.name} <br />
            </>
          )}
          {!!shouldShowReadingTime(blog) && (
            <em>
              Estimated reading time:{' '}
              {blog.estimatedReadingTime === 1
                ? '1 minute'
                : `${blog.estimatedReadingTime} minutes`}
            </em>
          )}
          {!!blog.difficultyLevel && (
            <em>
              Recipe difficulty: {CapitaliseSentence(blog.difficultyLevel)}
            </em>
          )}
        </Paragraph>
      </div>
    </Link>
  );
}
