import { PortableTextTypeComponentProps } from '@portabletext/react';

import { CustomImage, isCloudinaryAsset } from '@components/Image/Image';
import { CloudinaryAsset, SanityImage } from '@interfaces/Sanity';

export function ImageSetRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    width: number;
    images: Array<SanityImage | CloudinaryAsset>;
  }>
) {
  return (
    <div className="flex flex-wrap items-center justify-center gap-5">
      {props.value.images &&
        props.value.images.map((image) => (
          <CustomImage
            image={image}
            width={props.value.width}
            height={props.value.width}
            key={isCloudinaryAsset(image) ? image._key : image.asset._ref}
          />
        ))}
    </div>
  );
}
