import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useAddItem } from '@hooks/cart/useAddItem';
import { useCart } from '@hooks/cart/useCart';
import {
  checkCartQuantity,
  itemQuantityInCart,
  QuantityError,
} from '@lib/cart';
import { logBreadcrumb } from '@lib/utils';

import { AddToCartStatus } from './AddToCart';
import { Button, ButtonStyle, ButtonType } from './Button';

export function QuickAddToCartRecommended({
  productId,
  variantId,
  children,
  triggerCartModal = true,
  url,
  title,
  minQuantity = 1,
  maxQuantity = 9999,
}: {
  productId: number;
  variantId: number;
  children?: ReactNode;
  triggerCartModal?: boolean;
  url: string;
  title: string;
  minQuantity?: number;
  maxQuantity?: number;
}): ReactElement {
  const { cart } = useCart();
  const addItem = useAddItem({
    include: ['line_items.physical_items.options'],
  });
  const {
    displayCart: [quickCartIsOpen, setQuickCartIsOpen],
    recentlyViewed: { recentlyViewedIsOpen, setRecentlyViewedIsOpen },
    addingToCart: [addingToCartStatus, setAddingToCartStatus],
  } = useContext<UIContextInterface>(UIContext);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode>();
  const [qtyInCart, setQtyInCart] = useState<number>();

  // Extract line items from cart (memo no need for async)
  const cartItems = useMemo(() => {
    if (cart?.line_items.physical_items || cart?.line_items.digital_items) {
      return [
        ...cart.line_items.physical_items,
        ...cart.line_items.digital_items,
      ];
    } else {
      return [];
    }
  }, [cart?.line_items.physical_items, cart?.line_items.digital_items]);

  const addToCart = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    logBreadcrumb({
      category: 'AddToCart',
      message: `Clicked add to cart recommended button`,
      level: 'info',
      data: {
        productId,
        variantId,
      },
    });

    // Check against min/max quantities
    const quantityInCart = itemQuantityInCart(cartItems, variantId, productId);
    setQtyInCart(quantityInCart);
    if (quantityInCart > 0) {
      const quantityCheck = checkCartQuantity(
        quantityInCart,
        1,
        maxQuantity,
        minQuantity
      );
      if (quantityCheck) {
        setErrorMessage(
          <Paragraph>
            You can only purchase a
            {quantityCheck.error === QuantityError.max
              ? ' maximum '
              : ' minimum '}
            of{' '}
            {quantityCheck.error === QuantityError.max
              ? maxQuantity
              : minQuantity}{' '}
            of {title} per order and you already have {quantityInCart} in your
            cart
          </Paragraph>
        );
        setError(true);
        return;
      }
      setError(false);
    }

    try {
      setAddingToCartStatus(AddToCartStatus.Adding);
      if (triggerCartModal) setQuickCartIsOpen(true);
      const cartResponse = await addItem({
        lineItem: {
          productId,
          variantId,
          quantity: 1,
        },
      });

      if (cartResponse.error !== null) {
        setError(true);
        if (cartResponse.error) {
          setErrorMessage(
            <Paragraph>
              We couldn't add that to your cart. {cartResponse.error}
            </Paragraph>
          );
        } else {
          setErrorMessage(
            <Paragraph>
              We couldn't add that to your cart. Check the{' '}
              <TextLink href={url}>{title}</TextLink> page to see if stock is
              available
            </Paragraph>
          );
        }
      }

      setAddingToCartStatus(AddToCartStatus.Added);

      setRecentlyViewedIsOpen(false);
    } catch (error) {
      setAddingToCartStatus(AddToCartStatus.Failed);
      setError(true);
      setErrorMessage(
        <Paragraph>
          We couldn't add that to your cart. Check the{' '}
          <TextLink href={url}>{title}</TextLink> page to see if stock is
          available
        </Paragraph>
      );
    }
  };

  return (
    <>
      <ErrorAlert show={error} setShow={setError}>
        {errorMessage}
      </ErrorAlert>
      <form
        onSubmit={addToCart}
        className={'gae-product-atc'}
        data-product-title={title}
      >
        <input type="hidden" value={productId} name="productId" />
        <input type="hidden" value={variantId} name="variantId" />
        <Button
          type={ButtonType.submit}
          disabled={addingToCartStatus === AddToCartStatus.Adding}
          buttonStyle={ButtonStyle.secondary}
          className="h-fit max-w-full text-wrap"
        >
          {children}
        </Button>
      </form>
    </>
  );
}
