import React, { ReactElement, ReactNode } from 'react';

export function Aside({
  children,
  ...props
}: {
  children: ReactNode;
} & Record<string, any>): ReactElement {
  return (
    <aside
      className="clear-both mx-0 my-5 border border-solid 
      border-orange bg-orange-light px-5 py-2.5 text-black dark:bg-orange-dark dark:text-grey-light"
      {...props}
    >
      {children}
    </aside>
  );
}
