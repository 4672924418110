import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ReactElement } from 'react';

export function VimeoRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    altText?: string;
    videoId: string;
  }>
): ReactElement {
  return (
    <div className="relative isolate h-0 max-w-full overflow-hidden pb-[56.35%]">
      <iframe
        title={props.value.altText || ''}
        src={`https://player.vimeo.com/video/${props.value.videoId}`}
        width="640"
        height="360"
        style={{ border: '0px' }}
        allow="autoplay; fullscreen"
        allowFullScreen
        className="absolute left-0 top-0 h-full w-full"
      />
    </div>
  );
}
