import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ReactElement } from 'react';

export function TableRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    content: {
      rows: {
        cells: string[];
      }[];
    };
  }>
): ReactElement {
  return (
    <table className="mb-1 ml-0 mr-1 mt-0 inline-block align-top">
      <thead className="border border-grey-dark bg-grey-dark text-white">
        {props.value.content.rows.slice(0, 1).map((row, rowidx) => (
          <tr key={`thead-tr-${rowidx}`}>
            {row.cells.map((cell, cellidx) => (
              <th key={`th-${cellidx}`}>{cell}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="border border-grey">
        {props.value.content.rows.slice(1).map((row, rowidx) => (
          <tr
            key={`tr-${rowidx}`}
            className="text-black odd:bg-white even:bg-grey-light"
          >
            {row.cells.map((cell, cellidx) => (
              <td key={`td-${cellidx}`} className="px-5 py-[1px]">
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
