import { PortableTextTypeComponentProps } from '@portabletext/react';

export function YotpoReviewCarouselRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    autoplay: boolean;
    autoplaySpeed: number;
    count: number;
    productId: number;
    showBottomLine: boolean;
    showNavigation: boolean;
  }>
) {
  return (
    <div
      className="yotpo yotpo-reviews-carousel"
      data-background-color="transparent"
      data-mode="top_rated"
      data-type="per_product"
      data-count={props.value.count.toString()}
      data-show-bottomline={props.value.showBottomLine ? '1' : '0'}
      data-autoplay-enabled={props.value.autoplay ? '1' : '0'}
      data-autoplay-speed={props.value.autoplaySpeed.toString()}
      data-show-navigation={props.value.showNavigation ? '1' : '0'}
      data-product-id={props.value.productId}
    >
      &nbsp;
    </div>
  );
}
