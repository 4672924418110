export function formatUrl(value: string): string {
  if (value[0] === '/') {
    return value;
  }
  return `/${value}`;
}

export const normaliseRoute = (path: string): string => {
  if (path) {
    return path.slice(-1) === '/' ? path.slice(0, -1) : path;
  }
  console.error('Error: no path was provided on element, a slug is missing');
  // In the absolute worst case scenario where an item doesnt have a slug, have route to 404
  return '/404';
};

export const getBasePath = (path: string): string => {
  const queryIndex = path.indexOf('?');
  return queryIndex > -1 ? path.slice(0, queryIndex) : path;
};
