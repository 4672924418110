import { PortableTextMarkComponentProps } from '@portabletext/react';

import { ScrollLink } from '@components/Typography/TextLink/ScrollLink';
import {
  TextLink,
  TextLinkExternal,
} from '@components/Typography/TextLink/TextLink';
import { RewardsLink } from '@components/Typography/TextLink/TextLink';
import { formatUrl } from '@lib/formatUrl';

export function LinkRenderer(
  props: PortableTextMarkComponentProps<{
    _type: string;
    href: string;
    newTab?: boolean;
    noFollow?: boolean;
  }>
) {
  // On page link, scroll to it
  if (props.value?.href[0] === '#') {
    return (
      <ScrollLink id={props.value.href.slice(1)}>{props.children}</ScrollLink>
    );
  }

  // External link
  if (props.value?.href.startsWith('http')) {
    return (
      <TextLinkExternal
        href={props.value.href}
        target={props.value.newTab ? '_blank' : ''}
        rel={`noopener noreferrer ${props.value.noFollow ? 'nofollow' : ''}`}
      >
        {props.children}
      </TextLinkExternal>
    );
  }

  // Mail/phone link
  if (
    props.value?.href.startsWith('mailto') ||
    props.value?.href.startsWith('tel')
  ) {
    return <TextLink href={props.value.href}>{props.children}</TextLink>;
  }

  // Rewards link
  if (props.value?.href.startsWith('/rewards')) {
    return <RewardsLink href={props.value.href}>{props.children}</RewardsLink>;
  }

  // Regular next router link
  return props.value ? (
    <TextLink
      href={formatUrl(props.value.href)}
      target={props.value.newTab ? '_blank' : ''}
      rel={props.value.noFollow ? 'nofollow' : ''}
    >
      {props.children}
    </TextLink>
  ) : null;
}

export function InternalLinkRenderer(
  props: PortableTextMarkComponentProps<{
    _type: string;
    slug: {
      current: string;
    };
    newTab?: boolean;
    noFollow?: boolean;
  }>
) {
  if (props.value?.slug && props.value.slug.current) {
    const rel = [] as string[];
    if (props.value.noFollow) {
      rel.push('nofollow');
    }
    if (props.value.newTab) {
      rel.push('noopener');
      rel.push('noreferrer');
    }

    return (
      <TextLink
        href={formatUrl(props.value.slug.current)}
        target={props.value.newTab ? '_blank' : ''}
        rel={rel.join(' ')}
      >
        {props.children}
      </TextLink>
    );
  }
  return (
    <>
      <span>missing link</span>
    </>
  );
}

export function OnPageLinkRenderer(
  props: PortableTextMarkComponentProps<{
    _type: string;
    id: string;
  }>
) {
  return <span id={props.value?.id}>{props.children}</span>;
}
