import dynamic from 'next/dynamic';
import React, { ReactElement, ReactNode, useState } from 'react';
import { IconContext } from 'react-icons';
import { FaFacebookF, FaFacebookMessenger, FaPinterestP } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import { IoLogoReddit } from 'react-icons/io5';

import { Button } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { mergeStyles } from '@lib/styles';

import { CopyButton } from './CopyButton';

const Modal = dynamic(() =>
  import('../Modal/Modal').then((mod) => mod.ModalNoPortal)
);

function SocialIcon({ children }: { children: ReactNode }) {
  return (
    <span className="grid h-12 w-12 place-items-center bg-transparent text-black transition-all delay-0 duration-200 ease-in-out hover:bg-black hover:text-white dark:text-white dark:hover:text-grey-mid">
      {children}
    </span>
  );
}

export function SocialShares({
  type,
  shareSlug,
  titleText,
  imageSource = '', // leave empty string if there isn't a default image for a page
  className,
}: {
  type: string;
  shareSlug: string;
  titleText: string;
  imageSource?: string;
  className?: string;
}): ReactElement {
  const [showModal, setShowModal] = useState(false);

  const fullUrl = `https://www.bulknutrients.com.au${shareSlug}`;
  const titleEncoded = encodeURIComponent(titleText);

  return (
    <>
      <span
        className={mergeStyles(
          className ?? '',
          'text-base font-bold text-grey-darkest dark:text-white'
        )}
      >
        <Button
          onClick={(e) => setShowModal(true)}
          buttonStyle={ButtonStyle.text}
        >
          Share this {type}
        </Button>
      </span>
      {showModal && (
        <Modal
          show={showModal}
          setShow={setShowModal}
          className="dark:bg-black"
        >
          <IconContext.Provider value={{ size: '36px' }}>
            <div className="text-black dark:text-white">
              <h2 className="mb-8 mt-4 font-bold italic">
                Share this {type}'s link
              </h2>
              <div className="mb-8 flex flex-col">
                <span className="font-bold">
                  Click to copy this {type}'s link
                </span>
                <CopyButton copyText={fullUrl} />
              </div>

              <div className="mb-8 flex flex-col">
                <span className="font-bold">
                  Click to email this {type} to a friend
                </span>
                <a
                  href={`mailto:?subject=${titleEncoded}&body=Check%20out%20Bulk%20Nutrients:%20${titleEncoded}! ${fullUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ga-social-share"
                  data-social-id="email"
                  aria-label="Share by Email"
                >
                  <SocialIcon>
                    <HiOutlineMail />
                  </SocialIcon>
                </a>
              </div>

              <div className="flex flex-col">
                <span className="font-bold">
                  Click to share this {type} to social media
                </span>
                <div className="flex flex-wrap gap-6">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${fullUrl}&amp;utm_source=share&amp;utm_medium=facebook`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ga-social-share"
                    data-social-id="facebook"
                    aria-label="Share on Facebook"
                  >
                    <SocialIcon>
                      <FaFacebookF />
                    </SocialIcon>
                  </a>
                  <a
                    href={`fb-messenger://share?link=${encodeURIComponent(
                      fullUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mobile-only ga-social-share block sm:hidden"
                    data-social-id="messenger"
                    aria-label="Share on Messenger"
                  >
                    <SocialIcon>
                      <FaFacebookMessenger />
                    </SocialIcon>
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${fullUrl}&amp;utm_source=share&amp;utm_medium=twitter&text=${titleEncoded}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ga-social-share"
                    data-social-id="twitter"
                    aria-label="Share on Twitter"
                  >
                    <SocialIcon>
                      <FaXTwitter />
                    </SocialIcon>
                  </a>
                  <a
                    href={`https://www.pinterest.com.au/pin-builder/?url=${fullUrl}&media=${imageSource}&description=${titleEncoded}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ga-social-share"
                    data-social-id="pinterest"
                    aria-label="Share on Pinterest"
                  >
                    <SocialIcon>
                      <FaPinterestP />
                    </SocialIcon>
                  </a>
                  <a
                    href={`https://www.reddit.com/submit?url=${fullUrl}&amp;utm_source=share&amp;utm_medium=reddit&title=${titleEncoded}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ga-social-share"
                    data-social-id="reddit"
                    aria-label="Share on Reddit"
                  >
                    <SocialIcon>
                      <IoLogoReddit />
                    </SocialIcon>
                  </a>
                </div>
              </div>
            </div>
          </IconContext.Provider>
        </Modal>
      )}
    </>
  );
}
