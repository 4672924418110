/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link';
import { getCldImageUrl } from 'next-cloudinary';
import React, { ReactElement, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { TextLink } from '@components/Typography/TextLink/TextLink';
import { CloudinaryAsset, SanityImage } from '@interfaces/Sanity';
import { blurUrl, calculateHeight, urlFor } from '@lib/images';
import { mergeStyles } from '@lib/styles';

const OverlayMap = {
  pharmaceuticalGrade: {
    src: '/images/icon-100-pharmaceutical-grade.webp',
    alt: '100% pharmaceutical grade',
  },
  improvedFormula: {
    src: '/images/icon-improved-formula-icons.webp',
    alt: 'Now with a revised formulation',
  },
  sale: {
    src: '/images/icon-sale.webp',
    alt: 'This product is on sale',
  },
  hasta: {
    src: '/images/icon-hasta.webp',
    alt: 'This product is HASTA certified',
  },
  veganCertified: {
    src: '/images/icon-vegan-certified.webp',
    alt: 'This product is vegan certified',
  },
  plantBased: {
    src: '/images/icon-plant-based.webp',
    alt: 'This product is plant based',
  },
};

export function isCloudinaryAsset(
  item: SanityImage | CloudinaryAsset
): item is CloudinaryAsset {
  return (
    (item as CloudinaryAsset)._type === 'cloudinaryAsset' ||
    (item as CloudinaryAsset)._type === 'cloudinary.asset'
  );
}

export function isSanityImage(
  item: SanityImage | CloudinaryAsset
): item is SanityImage {
  return (item as SanityImage)._type === 'image';
}

export const getImageSrcUrl = ({
  image,
  width,
  height,
}: {
  image: SanityImage | CloudinaryAsset;
  width: number;
  height?: number;
}): string => {
  const quality = 90;
  try {
    if (isCloudinaryAsset(image)) {
      return getCldImageUrl({
        width: width,
        height: height,
        src: image.publicId ?? image.asset.public_id,
      });
    } else if (isSanityImage(image)) {
      if (height && image.hotspot) {
        return urlFor(image.asset)
          .auto('format')
          .width(width)
          .height(height)
          .fit('crop')
          .crop('focalpoint')
          .focalPoint(image.hotspot.x, image.hotspot.y)
          .quality(quality)
          .url() as string;
      } else if (height) {
        return urlFor(image.asset)
          .auto('format')
          .width(width)
          .height(height)
          .fit('fillmax')
          .quality(quality)
          .url() as string;
      } else {
        return urlFor(image.asset)
          .auto('format')
          .width(width)
          .fit('fillmax')
          .quality(quality)
          .url() as string;
      }
    }
  } catch (err) {
    console.error(err);
    console.log(image);
    return '';
  }
  return '';
};

function StyledNextImage({
  image,
  src,
  alt,
  showOverlay,
  width,
  height,
  href,
  round = false,
  priority = false,
  sizes,
  title,
  className,
  usePlaceholder,
}: {
  image?: SanityImage | CloudinaryAsset;
  src: string;
  alt?: string;
  showOverlay: boolean;
  width: number;
  height: number;
  href?: string;
  round?: boolean;
  priority?: boolean;
  sizes?: string;
  title?: string;
  className?: string;
  usePlaceholder?: boolean;
}) {
  // Add borders and floats as required. Add a min height to avoid CLS issues
  // these go on the <figure> wrapper
  const inlineStyles = [] as Array<string>;
  if (image) {
    if (image.border) {
      inlineStyles.push(
        'border border-solid border-grey-mid !p-2.5 bg-grey-light dark:border-grey-dark dark:bg-grey-dark'
      );
    }
    if (image.floatLeft) {
      inlineStyles.push('sm:float-left sm:mr-2.5 sm:max-w-[50%] sm:clear-both');
    }
    if (image.floatRight) {
      inlineStyles.push(
        'sm:float-right sm:ml-2.5 sm:max-w-[50%] sm:clear-both'
      );
    }
  }

  return (
    <figure
      className={mergeStyles(
        inlineStyles.join(' '),
        '[--background]:grey-mid [--border]:grey-mid dark:[--border]:grey-dark dark:[--background]:grey-dark relative mx-auto my-0 flex min-h-fit w-fit flex-col gap-1 dark:text-white'
      )}
    >
      <Image
        src={src}
        alt={image ? image.altText : (alt ?? '')}
        width={width}
        height={height}
        style={round ? { borderRadius: '50%' } : {}}
        className={mergeStyles(
          className ?? '',
          'mx-auto my-0 h-full max-w-full'
        )}
        priority={priority}
        unoptimized
        placeholder={usePlaceholder ? 'blur' : 'empty'}
        blurDataURL={
          image && isSanityImage(image) && image.lqip
            ? image?.lqip
            : blurUrl(width, height)
        }
        sizes={sizes}
        title={title}
      />
      {showOverlay &&
        image &&
        isSanityImage(image) &&
        image?.overlay &&
        image.overlay[0] &&
        Object.keys(OverlayMap).includes(image.overlay[0]) && (
          <div
            className={twJoin([
              'overlay',
              'absolute bottom-[calc(1.5_*_1.1rem)] right-0 h-[130px] w-[180px] bg-transparent',
            ])}
          >
            <Image
              src={OverlayMap[image.overlay[0]].src}
              alt={OverlayMap[image.overlay[0]].alt}
              width={180}
              height={130}
              className="mx-auto my-0 h-full max-w-full"
            />
          </div>
        )}
      {image?.caption && href ? (
        <figcaption className="text-sm text-grey-dark dark:text-grey-light">
          <TextLink href={href}>{image.caption}</TextLink>
        </figcaption>
      ) : image?.caption ? (
        <figcaption className="text-sm text-grey-dark dark:text-grey-light">
          {image.caption}
        </figcaption>
      ) : null}
    </figure>
  );
}

// Check if there is a link, and add it if so
function LinkCheckWrapper({
  image,
  children,
}: {
  image?: SanityImage | CloudinaryAsset;
  children: ReactNode;
}) {
  if (!image) {
    return <>{children}</>;
  }

  if (image.internalLink || (image.link && !image.link.startsWith('http'))) {
    // it has a link and is internal / relative
    const href = image.internalLink ? image.internalLink : image.link;
    return (
      <Link
        prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
        href={href}
        target={image.newTab ? '_blank' : ''}
        rel={`noreferrer ${image.noFollow ? 'nofollow' : ''}`}
      >
        {children}
      </Link>
    );
  } else if (image.link) {
    // has a link that is absolute
    const href = image.link;
    return (
      <a
        href={href}
        target={image.newTab ? '_blank' : ''}
        rel={`noreferrer ${image.noFollow ? 'nofollow' : ''}`}
      >
        {children}
      </a>
    );
  } else {
    // no link
    return <>{children}</>;
  }
}

interface Props {
  image?: SanityImage | CloudinaryAsset;
  src?: string;
  width: number;
  height?: number;
  showOverlay?: boolean;
  round?: boolean;
  priority?: boolean;
  alt?: string;
  sizes?: string;
  title?: string;
  className?: string;
  usePlaceholder?: boolean;
}

export function CustomImage({
  image,
  src,
  width,
  height,
  showOverlay = false,
  round = false,
  priority = false,
  alt,
  sizes,
  title,
  className,
  usePlaceholder = true,
}: Props): ReactElement {
  if (!image && !src) return <></>; // given nothing to render

  // make src from sanity asset, or string given
  let imgSrc: string;
  if (image) {
    try {
      imgSrc = getImageSrcUrl({ image, width, height });
    } catch (err) {
      imgSrc = '';
    }
  } else {
    imgSrc = src as string;
  }

  return (
    <LinkCheckWrapper image={image}>
      <StyledNextImage
        image={image}
        width={width}
        height={height ?? calculateHeight(width, image?.ratio) ?? width}
        showOverlay={showOverlay}
        src={imgSrc as string}
        round={round}
        href={image?.internalLink ? image.internalLink : image?.link}
        alt={alt}
        priority={priority}
        sizes={sizes}
        title={title}
        className={className}
        usePlaceholder={usePlaceholder}
      />
    </LinkCheckWrapper>
  );
}
