/**
 * Component to render the stars product taste etc
 * Tricky bit in here is the number of stars. We have a float coming in
 * so check if it has a .5 on end or not. Then make an array of the size of whole stars
 * to iterate over inserting a star. Add the half if needed
 */

import { IoStar, IoStarHalf, IoStarOutline } from 'react-icons/io5';

export function StarRating({ rating }) {
  const half = rating % 1 !== 0;
  const whole = Math.floor(rating);
  const empty = 5 - whole - +half;

  return (
    <span className="flex items-center text-orange">
      {Array.apply(null, Array(whole))
        .map(function () {})
        .map((a, i) => (
          <IoStar key={`star${i}`} />
        ))}
      {half && <IoStarHalf />}
      {empty > 0 &&
        Array.apply(null, Array(empty))
          .map(function () {})
          .map((a, i) => <IoStarOutline key={`emptystar${i}`} />)}
    </span>
  );
}
