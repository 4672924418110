/**
 * This component is used in the category faceted search pages
 * Displays image, name, short desc, and quick adds
 */
import Link from 'next/link';
import React, { ReactElement } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { MdOutlineCheckCircle } from 'react-icons/md';

import { AddToFavourites, Button, QuickAddButton } from '@components/Button';
import { CustomImage } from '@components/Image/Image';
import { StarRating } from '@components/Product/StarRating';
import { BaseProduct } from '@interfaces/Product';
import { mergeStyles } from '@lib/styles';

export function ProductCard({
  product,
  className,
}: {
  product: BaseProduct;
  className?: string;
}): ReactElement {
  const price =
    product.pricing.priceRange.min.value ===
    product.pricing.priceRange.max.value
      ? `$${product.pricing.priceRange.min.value.toFixed(2)}`
      : `$${product.pricing.priceRange.min.value.toFixed(
          2
        )} - $${product.pricing.priceRange.max.value.toFixed(2)}`;

  return (
    <div
      className={mergeStyles(
        className ?? '',
        'card grid h-full max-w-[450px] grid-cols-[100px_auto] content-between gap-0 tiny:grid-cols-[150px_auto]'
      )}
    >
      <Link
        prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
        href={product.slug}
      >
        <CustomImage
          image={
            product.images.filter((i) => i.defaultImage)[0] ?? product.images[0]
          }
          width={150}
          height={150}
          className="w-[100px] tiny:w-full"
        />
      </Link>

      <div className="relative flex min-h-[100px] flex-col bg-grey-light px-2 text-grey-dark dark:bg-grey-dark dark:text-white tiny:min-h-[150px]">
        <div className="flex h-8 justify-between">
          <h3 className="mt-1 font-secondary uppercase">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href={product.slug}
              className="line-clamp-1 cursor-pointer font-secondary text-2xl font-semibold text-grey-dark underline decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white dark:hover:text-white"
            >
              {product.shortName || product.name}
            </Link>
          </h3>
          <AddToFavourites
            sku={product.sku}
            productId={product.entityId}
            className="h-8"
          />
        </div>
        {!!product.averageRating && (
          <div className="mb-1 flex items-center gap-1 text-xs">
            <StarRating rating={product.averageRating} />
            {product.averageRating} from {product.totalReviews} reviews
          </div>
        )}
        <div className="grow">
          <div className="line-clamp-2 hidden text-ellipsis text-xs tiny:[display:-webkit-box]">
            {product.shortDescription}
          </div>
        </div>

        <div className="grid grid-cols-2 items-center font-secondary text-2xl font-bold">
          <span>{price}</span>

          <div className="flex gap-4 justify-self-end">
            <Button
              href={product.slug}
              aria-label="See more information about product"
              buttonStyle="tertiary"
              className="hover:scale-120"
              icon={<IoInformationCircleOutline size="1.5rem" />}
            />
            <QuickAddButton product={product} className="hover:scale-120" />
          </div>

          <span className="flex items-center font-secondary text-base font-light uppercase text-black dark:text-white">
            {!!product.pricePerServe && product.pricePerServe > 0 && (
              <>
                <MdOutlineCheckCircle className="fill-green dark:fill-green-light" />
                {product.pricePerServe < 1
                  ? `${Math.round(product.pricePerServe * 100).toFixed(0)}c`
                  : `$${product.pricePerServe.toFixed(2)}`}{' '}
                per serve
              </>
            )}
          </span>
          {(product.pricing.bulkPricing.length > 0 ||
            product.variants.some((v) => v.pricing.bulkPricing.length > 0)) && (
            <span className="flex items-center justify-self-end font-secondary text-base font-light uppercase text-black dark:text-white">
              <MdOutlineCheckCircle className="fill-green dark:fill-green-light" />
              Multibuy Discount
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
