import { PortableTextTypeComponentProps } from '@portabletext/react';

import { BlogCard } from '@components/Blog/BlogCard';
import { BulkCarousel } from '@components/Carousels/Carousel';
import { ProductCard } from '@components/ProductCard';
import { BaseBlog } from '@interfaces/Blog';
import { BaseProduct } from '@interfaces/Product';

interface CarouselProps {
  _type: string;
  _key: string;
  items: Array<BaseBlog | BaseProduct>;
}

function isProductCarousel(item: BaseBlog | BaseProduct): item is BaseProduct {
  return (item as BaseProduct).sku !== undefined;
}

export function CarouselRenderer(
  props: PortableTextTypeComponentProps<CarouselProps>
) {
  if (isProductCarousel(props.value.items[0])) {
    return (
      <BulkCarousel
        className="light-background full-bleed my-5"
        fadeTo="to-white dark:to-black"
      >
        {props.value.items.map((item) => (
          <ProductCard
            product={item as BaseProduct}
            key={(item as BaseProduct).entityId}
            className="w-[320px] tiny:w-[400px]"
          />
        ))}
      </BulkCarousel>
    );
  }
  return (
    <BulkCarousel
      className="light-background full-bleed my-5"
      fadeTo="to-white dark:to-black"
    >
      {props.value.items.map((item) => (
        <BlogCard blog={item as BaseBlog} key={item.slug} />
      ))}
    </BulkCarousel>
  );
}
