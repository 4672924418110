import type { PortableTextBlock } from '@portabletext/types';

export function TrimText(
  value: string,
  maxLength: number,
  ellipsis = true
): string {
  if (!value) return '';
  if (value.length > maxLength) {
    if (ellipsis) {
      return `${value.slice(0, maxLength - 3)}...`;
    }
    return `${value.slice(0, maxLength)}`;
  }
  return value;
}

/**
 * Capitalises the first letter of a string
 * @param str
 * @returns Str
 */
export function Capitalise(str: string): string {
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
}

/**
 * Capitalise each word in a sentence
 * @param str
 * @param ignoredWords array of words to ignore. e.g. ['and', 'if']
 * @returns Str In Capitals
 */
export function CapitaliseSentence(
  str: string,
  ignoredWords?: string[]
): string {
  return str
    .split(' ')
    .map((s) => (ignoredWords?.includes(s) ? s : Capitalise(s)))
    .join(' ');
}

/**
 * Take portable text and join together as a string
 * @param blocks
 * @returns
 */
export function portableTextToPlainText(
  blocks: PortableTextBlock[] = []
): string {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return '';
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join('');
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  );
}

/**
 * Take a number value and return it as currency string $x.xx
 * @param value
 * @returns
 */
export function money(value: number | string | null): string {
  return Number(value).toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
  });
}
