export async function copyLink(
  textForClipboard: string,
  onCopied: () => void,
  onFailed: () => void
) {
  // Check if the nav clipboard  API is available, i.e. safari <14 does not support.
  if (navigator.clipboard) {
    navigator.clipboard.writeText(textForClipboard).then(
      () => {
        // Show copied message if clipboard returns successfully
        onCopied();
      },
      () => {
        // Show copy fail message if clipboard writing fails, i.e. permissions blocked
        onFailed();
      }
    );
  } else {
    // We don't have the navigator.clipboard API available, so workaround is this...
    // TODO: remove this when Safari support is >13.1
    const textField = document.createElement('textarea');
    textField.innerText = textForClipboard;
    try {
      if (document.activeElement) {
        document.activeElement.appendChild(textField);
      } else {
        document.body.appendChild(textField);
      }
      textField.setSelectionRange(0, 99999);
      // Deprecated but have to use this as only mechanism available for older browsers
      document.execCommand('copy');
      textField.remove();

      onCopied();
    } catch (err) {
      onFailed();
    }
  }
}
