import { Cart, DigitalItem, PhysicalItem } from '@interfaces/ICart';

export enum QuantityError {
  max = 'EXCEEDS MAXIMUM QUANTITY',
  min = 'EXCEEDS MINIMUM QUANTITY',
  none = 'NONE',
}

export function itemQuantityInCart(
  cartItems: (PhysicalItem | DigitalItem)[],
  variantId: number,
  productId: number
) {
  const inCart = cartItems.filter(
    (item) => item.variant_id === variantId && item.product_id === productId
  )[0];

  return inCart ? inCart.quantity : 0;
}

/**
 * Check if we are going to exceed min/max requirements when adding to cart
 * @param cartItems
 * @param variantId
 * @param productId
 * @param quantity
 * @param maxQuantity
 * @param minQuantity
 * @returns {}
 */
export function checkCartQuantity(
  quantityInCart: number,
  quantity: number,
  maxQuantity: number,
  minQuantity: number
): {
  error: QuantityError;
} | null {
  const total = quantityInCart + quantity;
  if (total > maxQuantity) {
    return {
      error: QuantityError.max,
    };
  }
  if (total < minQuantity) {
    return {
      error: QuantityError.min,
    };
  }
  return null;
}

export const cartValueWithoutGiftCertificates = (cart: Cart) =>
  (cart?.cart_amount as number) -
  (cart?.line_items.gift_certificates.reduce(
    (acc, cur) => acc + cur.amount,
    0
  ) as number);
