/* eslint-disable react/jsx-one-expression-per-line */
import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ReactElement } from 'react';

import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { Recipe } from '@interfaces/Sanity';
import { CapitaliseSentence } from '@lib/formatText';

import { PortableTextBlockRenderer } from './PortableText';

export function RecipeRenderer(
  props: PortableTextTypeComponentProps<Recipe>
): ReactElement {
  return (
    <>
      <p className="text-center italic">
        <span className="inline-block px-2.5 py-0">
          Makes: {props.value.yield}
        </span>
        {props.value.calories && (
          <span className="inline-block px-2.5 py-0">
            Calories: {props.value.calories}
          </span>
        )}
        {props.value.difficultyLevel && (
          <span className="inline-block px-2.5 py-0">
            Difficulty Level: {CapitaliseSentence(props.value.difficultyLevel)}
          </span>
        )}
        <span className="inline-block px-2.5 py-0">
          Preparation time: {props.value.prepTime} minutes
        </span>
        <span className="inline-block px-2.5 py-0">
          Cook time: {props.value.cookTime} minutes
        </span>
        <span className="inline-block px-2.5 py-0">
          Total time: {props.value.totalTime} minutes
        </span>
      </p>
      <h2 className="pt-4 italic">Ingredients</h2>
      {props.value.ingredientsSection &&
        props.value.ingredientsSection.map((ingredientBlock, i) => (
          <div key={`ingredientsBlock-${i}`}>
            {ingredientBlock.description && (
              <Paragraph>{ingredientBlock.description}</Paragraph>
            )}
            <ul className="my-4 pl-10">
              {ingredientBlock.ingredients.map((ingredient, idx) => (
                <li className="list-disc" key={`ingredient-${idx}`}>
                  {ingredient}
                </li>
              ))}
            </ul>
          </div>
        ))}

      <h2 className="pt-4 italic">Instructions</h2>
      {props.value.instructionsSection &&
        props.value.instructionsSection.map((instructionBlock, i) => (
          <div key={`ingredientsBlock-${i}`}>
            {instructionBlock.description && (
              <Paragraph>{instructionBlock.description}</Paragraph>
            )}
            <ol className="list-none pl-5">
              {instructionBlock.instructions.map((instruction, idx) => (
                <li
                  key={`instruction-${i}-${idx}`}
                  className="grid grid-cols-[55px_1fr] gap-2.5"
                >
                  <strong>Step {idx + 1}: </strong>
                  <span>{instruction.description}</span>
                </li>
              ))}
            </ol>
          </div>
        ))}

      {props.value.nutritionPanels && (
        <h3 className="pt-4 italic">Nutrition</h3>
      )}
      {props.value.nutritionPanels &&
        props.value.nutritionPanels.map((panel) => (
          <PortableTextBlockRenderer key={panel._key} content={panel} />
        ))}
    </>
  );
}
