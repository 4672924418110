import React, { ReactElement } from 'react';
import {
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { TextLink } from '@components/Typography/TextLink/TextLink';

export function SocialLinks({
  name,
  noFollow,
  facebook,
  instagram,
  twitter,
  tiktok,
  linkedIn,
  website,
  youTube,
}: {
  name: string;
  noFollow: boolean;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  tiktok?: string;
  linkedIn?: string;
  website?: string;
  youTube?: string;
}): ReactElement {
  let rel = '';
  // do not allow follow all social links if noFollow is selected in Sanity
  if (noFollow) {
    rel = 'nofollow';
  }

  return (
    <>
      <p className="mx-0 mb-5 mt-2.5 flex justify-center gap-2.5">
        {facebook && (
          <TextLink href={facebook} rel={rel}>
            <FaFacebook role="img" aria-label={`${name} facebook`} size={24} />
          </TextLink>
        )}
        {instagram && (
          <TextLink href={instagram} rel={rel}>
            <FaInstagram
              role="img"
              aria-label={`${name} instagram`}
              size={24}
            />
          </TextLink>
        )}
        {twitter && (
          <TextLink href={twitter} rel={rel}>
            <FaXTwitter role="img" aria-label={`${name} twitter`} size={24} />
          </TextLink>
        )}
        {tiktok && (
          <TextLink href={tiktok} rel={rel}>
            <FaTiktok role="img" aria-label={`${name} tiktok`} size={24} />
          </TextLink>
        )}
        {youTube && (
          <TextLink href={youTube} rel={rel}>
            <FaYoutube role="img" aria-label={`${name} youtube`} size={24} />
          </TextLink>
        )}
        {linkedIn && (
          <TextLink href={linkedIn} rel={rel}>
            <FaLinkedin role="img" aria-label={`${name} linked in`} size={24} />
          </TextLink>
        )}
        {website && (
          <TextLink href={website} rel={rel}>
            <FaGlobe role="img" aria-label={`${name} website`} size={24} />
          </TextLink>
        )}
      </p>
    </>
  );
}
