import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ReactElement } from 'react';

export function YotpoGalleryRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    galleryId: string;
    productId: number;
  }>
): ReactElement {
  return (
    <div
      className="yotpo yotpo-pictures-widget"
      data-gallery-id={props.value.galleryId}
      data-product-id={props.value.productId}
    />
  );
}
