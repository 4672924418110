import { ReactElement, useState } from 'react';

import { Button } from '@components/Button';

export function BulletListRenderer(props: any) {
  const items: number = props.children.length;
  const columns = Math.min(Math.max(Math.floor(items / 20), 1), 4);
  const [displayNumber, setDisplayNumber] = useState(20 * columns);

  return (
    <div>
      <ul
        className="m-0 mb-5 ml-5 list-disc columns-[--max-column-count] p-0 pl-5"
        style={
          {
            '--max-column-count': columns,
          } as React.CSSProperties
        }
      >
        {props.children.slice(0, displayNumber)}
      </ul>
      {items > displayNumber && (
        <div className="flex justify-center">
          <Button onClick={(e) => setDisplayNumber(displayNumber + 20)}>
            More
          </Button>
        </div>
      )}
    </div>
  );
}

export function NumberedListRenderer(props): ReactElement {
  return (
    <div>
      <ol className="m-0 ml-5 list-decimal break-words p-0">
        {props.children}
      </ol>
    </div>
  );
}
