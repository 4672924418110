import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ReactElement } from 'react';

import { QuickAddToCartRecommended } from '@components/Button/QuickAddToCartRecommended';
import { CustomImage } from '@components/Image';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { SanityImage } from '@interfaces/Sanity';
import { mergeDeep } from '@lib/deepMerge';

export function RecommendedProductRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    how: string;
    product: {
      images: SanityImage[];
      optionTags: string[];
      options: {
        title: string;
        value: string;
      }[];
      parent: {
        image: SanityImage;
        slug: string;
        title: string;
        maxPurchaseQty: number;
        minPurchaseQty: number;
      };
      price: number;
      productId: number;
      sku: string;
      variantId: number;
    };
    why: string;
  }>
): ReactElement {
  if (!props.value.product.parent) return <></>;

  const image = mergeDeep({}, props.value.product.parent.image, {
    link: props.value.product.parent.slug,
  }) as SanityImage;

  return (
    <div className="card flex flex-col justify-between p-2.5">
      <CustomImage image={image} width={200} />
      <TextLink href={props.value.product.parent.slug}>
        <h3 className="text-center">{props.value.product.parent.title}</h3>
      </TextLink>
      <Paragraph>
        <strong>Why I love it:</strong> {props.value.why}
      </Paragraph>
      <Paragraph>
        <strong>How I use it:</strong> {props.value.how}
      </Paragraph>
      <QuickAddToCartRecommended
        productId={props.value.product.productId}
        variantId={props.value.product.variantId}
        title={props.value.product.parent.title}
        url={props.value.product.parent.slug}
        maxQuantity={props.value.product.parent.maxPurchaseQty}
        minQuantity={props.value.product.parent.minPurchaseQty}
      >
        Add{' '}
        {props.value.product.options &&
          props.value.product.options.map((o) => o.value).join(' ')}{' '}
        {props.value.product.parent.title} to your cart
      </QuickAddToCartRecommended>
    </div>
  );
}
