import { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { mergeStyles } from '@lib/styles';

export function ScrollLink({
  children,
  id,
  className,
  ...props
}: {
  children: ReactNode;
  id: string;
  className?: string;
} & Record<string, any>): ReactElement {
  const style = `
    bg-inherit 
    border-none 
    p-0 
    text-start 
  `;

  return (
    <button
      className={mergeStyles(className ?? '', twMerge([style, 'clickable']))}
      onClick={() => document.getElementById(id)?.scrollIntoView()}
      {...props}
    >
      {children}
    </button>
  );
}
